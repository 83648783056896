import imgAurore from '@assets/images/testimonials/Aurore-Gruchy.png'
import imgSandrine from '@assets/images/testimonials/Sandrine-Henry.png'
import casMaisonJeunes from '@assets/pdf/ETUDE-MAISON-DES-JEUNES-202407.pdf'
import casMosaique from '@assets/pdf/ETUDE-MOSAIQUE-202407.pdf'

const content = [
    {
        name: 'Sandrine Henry,',
        qualification: 'Directrice de La Maison des Jeunes de Neuville',
        testimonial:
            '"Staffea s’adapte aux besoins spécifiques des centres de loisirs. J’ai gagné deux jours par mois. J’ai plus de temps pour du travail à forte valeur ajoutée : recherche de subventions, de financements pour développer de nouvelles activités, faire des appels à projet et aussi passer plus de temps avec les équipes, aller les voir sur le terrain."',
        image: imgSandrine,
        link: casMaisonJeunes,
    },
    {
        name: 'Aurore Gruchy,',
        qualification: 'Directrice du Centre Social Mosaïque',
        testimonial:
            '"Dès sa mise en place, l’application Staffea m’a fait gagner du temps sur la gestion des plannings et le suivi des horaires et des variables de paie. Quant aux salariés, ils ont un meilleur équilibre vie professionnelle / vie privée grâce à la gestion précise des temps de travail."',
        image: imgAurore,
        link: casMosaique,
    },
]

export default content
