import pictoHours from '@assets/svg/picto-alsh-control.svg'
import pictoLeaves from '@assets/svg/picto-alsh-leaves.svg'
import pictoPlanning from '@assets/svg/picto-alsh-planning.svg'

const functionalities = [
    {
        id: 1,
        title: 'Étape 1 : Vous construisez le planning',
        image: pictoPlanning,
        list: [
            'Renseignez vos besoins en personnel',
            'Créez vos horaires et vos roulements',
            'Contrôlez l’adéquation avec les contraintes réglementaires et conventionnelles (FPH, FHP, CC51, CC66)',
        ],
    },
    {
        id: 2,
        title: 'Étape 2 : Intégrez le planning dans la chaîne de valeur RH',
        image: pictoLeaves,
        list: [
            'Dématérialisez les demandes d’absences',
            'Suivez les compteurs de récupération et les droits à congés',
            'Calculez et transmettez les variables de paie',
            'Intégrez Staffea à votre SIRH au moyen d’interfaces web (API)',
        ],
    },
    {
        id: 3,
        title: 'Étape 3 : Actualisez facilement vos plannings au jour le jour',
        image: pictoHours,
        list: [
            'Mettez à jour votre planning grâce à une solution ergonomique et intuitive',
            'Suivez et validez les heures supplémentaires réalisées',
            'Trouvez une personne disponible en dernière minute dans votre établissement',
            'Publiez les changements en temps réel sur le smartphone de vos collaborateur·rice·s',
        ],
    },
]

export default functionalities
