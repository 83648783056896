import React from 'react'

import { Box, Flex, Image, Text, useMediaQuery } from '@chakra-ui/react'
import { device } from '@helpers/mediaQueryBreakpoints'
import { AiOutlineArrowRight } from 'react-icons/ai'

import IconLink from './IconLink'

interface TestimonialProps {
    content: {
        name: string
        qualification: string
        testimonial: string
        link: string
        image: string
    }[]
}

const Testimonial = ({ content }: TestimonialProps) => {
    const [isTablet] = useMediaQuery(device.tb)

    return (
        <Flex direction={isTablet ? 'column' : 'row'} gap="10" flexWrap="wrap">
            {content.map((item) => (
                <Flex key={item.name} direction="row" gap="5" flex={1}>
                    <Image
                        src={item.image}
                        objectFit="cover"
                        width={'120px'}
                        height={'120px'}
                        borderRadius="100%"
                        boxShadow={'9px 9px 33px rgba(164, 157, 189, 0.55)'}
                        mt="5px"
                    />

                    <Flex direction="column" gap="5">
                        <Box>
                            <Text fontSize="xs" color="indigo.500" fontWeight="bold">
                                {item.name}
                            </Text>

                            <Text fontSize="xs" fontWeight="bold" color="indigo.500">
                                {item.qualification}
                            </Text>
                        </Box>

                        <Text fontSize="xs" color="indigo.500">
                            {item.testimonial}
                        </Text>

                        <IconLink
                            iconPosition="left"
                            text="Télécharger l’étude de cas"
                            to={item.link}
                            icon={AiOutlineArrowRight}
                            color="green.500"
                            hoverColor="indigo.500"
                            isExternalLink
                        />
                    </Flex>
                </Flex>
            ))}
        </Flex>
    )
}
export default Testimonial
