import imgConformite from '@assets/images/alsh/conformite.png'
import imgNotif from '@assets/images/alsh/notification.png'
import imgPrepay from '@assets/images/alsh/prepaie.png'
import imgPlanning from '@assets/images/alsh/trame-planning.png'
import imgTransfert from '@assets/images/alsh/transfert.png'

const content = [
    {
        description: 'Créez les trames de plannings et des roulements des équipes en 2h',
        image: imgPlanning,
        alt: "Staffea, application web écran d'automatisation des plannings",
    },
    {
        description: 'Recherchez automatiquement les remplaçants en fonction des disponibilités des intervenants',
        image: imgTransfert,
        alt: 'Staffea, application web écran de remplacement',
    },
    {
        description: "Recevez les notifications d'absences ou de modification de planning en temps réel",
        image: imgNotif,
        alt: "Staffea, application web écran d'accueil avec notifications de mise à jour",
    },
    {
        description: 'Suivez les temps de présence pour générer la prépaie (SILAE, SAGE, ADP, CEGID)',
        image: imgPrepay,
        alt: "Staffea, application web écran d'accueil avec notifications de mise à jour",
    },
    {
        description: 'Contrôle automatique de la conformité des plannings',
        image: imgConformite,
        alt: "Staffea, application web écran d'accueil avec notifications de mise à jour",
    },
]

export default content
