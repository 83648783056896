/* eslint-disable react/jsx-newline */

/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'

import imgPlanif from '@assets/images/backgrounds/hero-alsh.webp'
import imgEtudeAlsh from '@assets/images/etude-ALSH-cover.jpg'
import etudeAlsh from '@assets/pdf/ETUDE-ALSH-2024.pdf'
import { Box, Divider, Flex, Heading, Icon, Image, Text, useMediaQuery } from '@chakra-ui/react'
import Seo from '@components/Seo'
import Section from '@components/core/Section'
import { CtaButton } from '@components/ui/Button'
import { HorizontalCard } from '@components/ui/Card'
import { FullScreenCarousel } from '@components/ui/Carousel'
import { MobileDisplay } from '@components/ui/Display'
import IconItemList from '@components/ui/IconItemList'
import IconLink from '@components/ui/IconLink'
import MenuSticky from '@components/ui/MenuSticky'
import Testimonial from '@components/ui/Testimonial'
import { functionalities, horizontalCarouselContent, testimonials } from '@data/alsh'
import { ANDROID_STORE_URL, APP_STORE_URL, CALENDLY_URL } from '@helpers/url'
import { graphql } from 'gatsby'
import { FaLongArrowAltRight } from 'react-icons/fa'
import { FaHeart } from 'react-icons/fa6'
import { IoLogoApple, IoLogoGooglePlaystore } from 'react-icons/io5'
import { IoArrowDownCircleOutline } from 'react-icons/io5'
import { v4 as uuidv4 } from 'uuid'

const AlshPage = () => {
    const [isTablet, isDesktop] = useMediaQuery(['(min-width: 768px)', '(min-width: 1000px)'])

    return (
        <>
            <Seo
                title="Logiciel Planning ALSH"
                description="Découvrez Staffea pour la gestion de vos plannings en ALSH ➜ ✓ Installation rapide ✓ Création en 2H ✓ Gestion en temps réel ➜ Demandez une démo !"
            />

            <main>
                <MenuSticky>
                    <Section
                        id="alsh-staffea-section"
                        name="staffea"
                        backgroundImage={isDesktop ? imgPlanif : undefined}
                        backgroundPosition="right top"
                        backgroundRepeat="no-repeat"
                        backgroundSize="contain"
                        aria-label="Enfants jouent sous la supervision d'une animatrice en ALSH"
                        pb={isDesktop ? 55 : 0}
                        mb={isDesktop ? -55 : 0}
                    >
                        <Flex direction="column" align="start" textAlign="left" maxW="850" pt={20} pb={16} wrap="wrap">
                            <Heading
                                as="h1"
                                fontWeight="bold"
                                color="indigo.500"
                                maxW={isDesktop ? '70%' : '100%'}
                                mb={8}
                            >
                                ALSH : planifiez vos équipes, suivez leurs temps de travail, simplement, rapidement.
                            </Heading>

                            <Text
                                as="h2"
                                fontSize="lg"
                                fontWeight="bold"
                                mb={6}
                                color="indigo.500"
                                maxW={isDesktop ? '70%' : '100%'}
                            >
                                Une application de planification d&#39;équipe adaptée aux enjeux des ALSH
                            </Text>

                            <Flex direction="column" w={['100%', '100%', '50%']} pt="6">
                                <a href={CALENDLY_URL} target="_blank" rel="noreferrer">
                                    <CtaButton
                                        text="Demander une démo"
                                        description="Démonstration de 30 minutes en visioconférence"
                                    />
                                </a>
                            </Flex>
                        </Flex>
                    </Section>

                    <Section bg="transparent" id="alsh-stores-section" pb={16} name="Disponible sur mobile">
                        <Flex
                            direction={['column', 'column', 'row']}
                            alignItems="center"
                            justifyContent="center"
                            bg="indigo.100"
                            borderRadius="md"
                            padding="25"
                            gap="35"
                        >
                            <Text fontSize="sm" align="center" ml="15" color="indigo.500">
                                Disponible sur App Store et Google Store
                            </Text>

                            <Flex direction="row" gap={5} alignItems="center">
                                <IconLink
                                    text=""
                                    isExternalLink
                                    to={APP_STORE_URL}
                                    boxSize={10}
                                    icon={IoLogoApple}
                                    color="indigo.500"
                                    hoverColor="green.500"
                                />

                                <Text fontSize="xl" align="center">
                                    &
                                </Text>

                                <IconLink
                                    to={ANDROID_STORE_URL}
                                    text=""
                                    isExternalLink
                                    boxSize={9}
                                    icon={IoLogoGooglePlaystore}
                                    color="indigo.500"
                                    hoverColor="green.500"
                                    mt={2}
                                />
                            </Flex>

                            {isTablet && (
                                <Divider
                                    borderColor="indigo.300"
                                    height="12"
                                    orientation="vertical"
                                    borderWidth="2"
                                    width={3}
                                />
                            )}

                            <Text fontSize="xl" align="center" color="indigo.500" fontWeight="bold">
                                + de 1000 clients satisfaits
                            </Text>
                        </Flex>
                    </Section>

                    <Section
                        bg={`linear-gradient(to top left, var(--chakra-colors-cadet) -3%, var(--chakra-colors-ultramarine) 20%, var(--chakra-colors-turquoise) 90%)`}
                        id="alsh-screens-section"
                        name="Fonctionnalités"
                        py="65"
                    >
                        <FullScreenCarousel
                            content={horizontalCarouselContent.map(({ description, image, alt }) => {
                                return {
                                    description: (
                                        <Text color="indigo.500" fontSize="sm" align="start">
                                            {description}
                                        </Text>
                                    ),
                                    image: <Image key={uuidv4()} src={image} alt={alt} />,
                                }
                            })}
                        />
                    </Section>

                    <Section id="alsh-testimonials-section" name="Témoignages" bg="indigo.100" py="65">
                        <Flex gap="2" alignItems={'center'}>
                            <Icon as={FaHeart} color="pink.500" w="4" h="4" />

                            <Text as="h3" fontSize="sm" color="pink.500" textTransform={'uppercase'}>
                                Ils ont choisi Staffea
                            </Text>
                        </Flex>

                        <Text as="h2" fontSize="xl" fontWeight="bold" mb={6} color="indigo.500">
                            Témoignages clients
                        </Text>

                        <Testimonial content={testimonials} />
                    </Section>

                    <Section
                        id="alsh-features-section"
                        name="Comment ça marche"
                        footer={
                            <Section bg="indigo.500">
                                <Flex
                                    w="fit-content"
                                    mx="auto"
                                    py="6"
                                    gap={6}
                                    align="center"
                                    direction={['column', 'column', 'row']}
                                >
                                    <Text as="span" variant="inline" color="white" fontSize="sm" align="center">
                                        Staffea réduit <Text variant="bold">drastiquement</Text> le temps de saisie et
                                        de modification des plannings de vos équipes.
                                    </Text>

                                    <Icon
                                        as={FaLongArrowAltRight}
                                        color="white"
                                        w="6"
                                        h="6"
                                        display={['none', 'none', 'block']}
                                    />

                                    <a href={CALENDLY_URL} target="_blank" rel="noreferrer">
                                        <CtaButton text="Réserver une démo !" width="200px" hoverColor="green.500" />
                                    </a>
                                </Flex>
                            </Section>
                        }
                    >
                        <Box py={20}>
                            <Text as="h2" fontSize="xl" fontWeight="bold" color="indigo.500">
                                Comment ça marche ?
                            </Text>

                            <Flex my="10" gap="20" direction="column" mx="auto">
                                {functionalities.map(({ id, title, image, images, list }) =>
                                    !isTablet && images ? (
                                        <Box key={id} w={['xs', 'sm']} mx="auto">
                                            <Box>
                                                <Box>
                                                    <Text as="h3" fontWeight="bold" color="green.500" mb="4">
                                                        {title}
                                                    </Text>

                                                    <IconItemList list={list} />
                                                </Box>

                                                <Flex
                                                    mt="8"
                                                    direction="column"
                                                    align="center"
                                                    gap="35"
                                                    flexDirection="row"
                                                    justifyContent="center"
                                                >
                                                    {images.map((image) => (
                                                        <MobileDisplay
                                                            width="125px"
                                                            height="261px"
                                                            key={uuidv4()}
                                                            transform="scale(0.3) translateX(-137px) translateY(-290px)"
                                                            media={<Image src={image.image} alt={image.alt} />}
                                                        />
                                                    ))}
                                                </Flex>
                                            </Box>
                                        </Box>
                                    ) : (
                                        <HorizontalCard
                                            key={id}
                                            w={['xs', 'sm', '2xl', '3xl', '4xl']}
                                            mediaW={['xs', 'sm', 'md']}
                                            contentW={['xs', 'sm', 'md']}
                                            mediaFlexBasis={['50%', '50%', '50%', '70%']}
                                            isReverse={id % 2 === 0}
                                            gap={['4', '8']}
                                            hasCenteredContent={true}
                                            media={
                                                images ? (
                                                    <Flex gap="4" mx="auto" transform="translateX(-20px)">
                                                        {images.map((image) => (
                                                            <MobileDisplay
                                                                width="125px"
                                                                height="360px"
                                                                key={uuidv4()}
                                                                transform="scale(0.3) translateX(-137px) translateY(-120px)"
                                                                media={<Image src={image.image} alt={image.alt} />}
                                                            />
                                                        ))}
                                                    </Flex>
                                                ) : (
                                                    <Image
                                                        src={image}
                                                        objectFit="cover"
                                                        maxW="60"
                                                        bgColor={'indigo.100'}
                                                        px={30}
                                                    />
                                                )
                                            }
                                            title={
                                                <Box>
                                                    <Text as="h3" fontWeight="bold" color="green.500" mb="4">
                                                        {title}
                                                    </Text>
                                                </Box>
                                            }
                                            content={<IconItemList list={list} />}
                                        />
                                    )
                                )}
                            </Flex>
                        </Box>
                    </Section>

                    <Section bg="white" id="alsh-study-section" pb={16} pt={16} name="Notre étude 2024">
                        <Text as="h2" fontSize="xl" fontWeight="bold" mb={6} color="indigo.500">
                            Staffea, à vos côtés pour mieux vous accompagner au quotidien dans une gestion sécurisée et
                            rassurante
                        </Text>

                        <Text color="indigo.500" fontSize="sm" align="start">
                            Du 15 juin au 15 septembre 2024, Staffea a mené une enquête pour mieux comprendre les enjeux
                            des directeur.rice.s d’ALSH en matière de planification de leurs animateurs. Découvrez les
                            enseignements de cette étude dans une infographie exclusive et comparez-vous à vos pairs.
                        </Text>

                        <Flex bg="indigo.100" mt="8" flexDirection="row" justifyContent="stretch" maxHeight={'140px'}>
                            <Flex p={5} width="50%" justifyContent="center" alignItems="center">
                                <IconLink
                                    text="Télécharger l’étude"
                                    to={etudeAlsh}
                                    isExternalLink
                                    boxSize={8}
                                    icon={IoArrowDownCircleOutline}
                                    color="indigo.500"
                                    hoverColor="green.500"
                                />
                            </Flex>

                            <Image width="50%" src={imgEtudeAlsh} objectFit="cover" />
                        </Flex>
                    </Section>
                </MenuSticky>
            </main>
        </>
    )
}

export default AlshPage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["alsh", "404"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
